import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  FullPhoto,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
} from "components/CaseLayout";

import { SOCIALMONSTERNEW } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const SocialMonsterNew = () => {
  const data = useCaseData(SOCIALMONSTERNEW);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo}/>
      <Hero data={data.hero}/>
      <Navigation type={SOCIALMONSTERNEW}/>
      <WhatWeDid data={data.what_we_did}/>
      <FullPhoto data={data.full_images.first}/>
      <FullPhoto data={data.full_images.second}/>
      <FullPhoto data={data.full_images.third}/>
      <FullPhoto data={data.full_images.fourth}/>
      <TextBox background="#FE8EFF" color="#fff" data={data.text_boxes.first}/>
      <FullPhoto data={data.full_images.fifth}/>
      <FullPhoto data={data.full_images.sixth}/>
      <FullPhoto data={data.full_images.seventh}/>
      <FullPhoto data={data.full_images.eighth}/>
      <FullPhoto data={data.full_images.ninth}/>
      <FullPhoto data={data.full_images.tenth}/>
      <FullPhoto data={data.full_images.eleventh}/>
      <FullPhoto data={data.full_images.twelfth}/>
      <FullPhoto data={data.full_images.thirteenth}/>
      <TextBox background="#2EB9FF" color="#fff" data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.fourteenth}/>
      <FullPhoto data={data.full_images.fifteenth}/>
      <TextBox background="#2EB9FF" color="#fff" data={data.text_boxes.third}/>
      <FullPhoto data={data.full_images.sixteenth}/>
      <FullPhoto data={data.full_images.seventeenth}/>
      <FullPhoto data={data.full_images.eighteenth}/>
      <FullPhoto data={data.full_images.nineteenth}/>
      <FullPhoto data={data.full_images.twentyth}/>
      <FullPhoto data={data.full_images.twentyFirst}/>
      <FullPhoto data={data.full_images.twentySecond}/>
      <ContactForm/>
    </Layout>
  );
};
export default SocialMonsterNew;
